import * as React from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';

const FORMSPARK_ACTION_URL = "https://submit-form.com/iseC7w9V";
const BOTPOISON_PUBLIC_KEY = "pk_e56a10dd-1e3a-423b-ba50-cac30ed6ce70";



const ContactPage = () => {

    const { headerImage } = useStaticQuery(
        graphql`
    query {
      headerImage: file(relativePath: { eq: "cover-contact-2800x1800.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
    `
    );




    return (

        <Layout pageTitle="Contact Me">

            <Helmet>
                <script src="https://unpkg.com/@botpoison/browser"></script>
            </Helmet>


            <BgImage Tag="section" className="py-10 py-md-10 overlay overlay-black overlay-60 bg-cover" image={headerImage.childImageSharp.gatsbyImageData} alt="">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">

                            <h1 className="display-2 fw-bold text-white text-shadow">
                                Get In Touch
                            </h1>

                            <p className="lead text-white-75 mb-0  text-shadow">
                                I look forward to connecting with you!
                            </p>

                        </div>
                    </div>
                </div>
            </BgImage>

            <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x text-light">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
                    </svg>
                </div>
            </div>



            <section className="pt-8 pt-md-11 pb-8 pb-md-14 pt-10 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-10">

                            <form method="POST" action={FORMSPARK_ACTION_URL} data-botpoison-public-key={BOTPOISON_PUBLIC_KEY}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-5">

                                            <label className="form-label" htmlFor="contactName">
                                                Full name
                                            </label>

                                            <input name="name" className="form-control" id="contactName" type="text" placeholder="Full name" />

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-5">

                                            <label className="form-label" htmlFor="contactEmail">
                                                Email
                                            </label>

                                            <input name="email" className="form-control" id="contactEmail" type="email" placeholder="hello@domain.com" />

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-7 mb-md-9">

                                            <label className="form-label" htmlFor="contactMessage">
                                                What project can I help you with?
                                            </label>

                                            <textarea name="message" className="form-control" id="contactMessage" rows="5" placeholder="Tell me what I can help you with!"></textarea>

                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-auto">

                                        <button type="submit" className="btn btn-primary lift">
                                            Send message
                                        </button>

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>




        </Layout>


    )
}

export default ContactPage
